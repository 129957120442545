import React, { useState, useEffect } from "react";
import moment from "moment";

export default function TimerCountDown({ time, nextPrice }) {
  const [countTime, setCountDateTime] = useState({
    time_days: 0,
    time_Hours: 0,
    time_Minusts: 0,
    time_seconds: 0,
  });
  const startTime = async () => {
    let until = moment.unix(time).format("x");
    let interval = setInterval(() => {
      let now = moment().format("x");
      const distance = +until - +now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setCountDateTime({
          ...countTime,
          time_days: days,
          time_Hours: hours,
          time_Minusts: minuts,
          time_seconds: seconds,
        });
      }
    }, 1000);
  };
  useEffect(() => {
    startTime();
  }, [time]);
  return (
    <>
      <p>
        {countTime.time_days} days, {countTime.time_Hours} hours,{" "}
        {countTime.time_Minusts} mins, {countTime.time_seconds} sec <br />{" "}
        remaining until price <br />{" "}
        <em>${+nextPrice > 0 ? 1 / +nextPrice : "0"}</em> <br />{" "}
        <i className="fa-solid fa-star" />
      </p>
    </>
  );
}
