import React from "react";

export default function OurPlan() {
  return (
    <section
      className="roadmapsec tophead"
      id="road"
      style={{
        background:
          "url(wp-content/themes/ogarmytheme/assets/images/armybg.jpg) no-repeat top left",
      }}
    >
      <div className="container">
        <h2>
          OUR <br /> <strong>BATTLE PLAN</strong>
        </h2>
        <div className="roadmaprwin">
          <div className="row">
            <div className="col-md-6">
              <div className="roadmaptxt">
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/roadmap_star.png"
                  alt=""
                />
                <span>
                  Phase <em>01</em>
                </span>
                {/* <h4>Q1 2024</h4> */}
                <p>
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "#c0db31" }}
                  />{" "}
                  Defined mission
                </p>
                <p>
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "#c0db31" }}
                  />{" "}
                  Onboard marketing partners
                </p>
                <p>
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "#c0db31" }}
                  />{" "}
                  Communal channels
                </p>
                <p>
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "#c0db31" }}
                  />{" "}
                  Whitepaper publish
                </p>
                <p>
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "#c0db31" }}
                  />{" "}
                  Recruitment of initial OG Army soldiers
                </p>
                <p>
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "#c0db31" }}
                  />{" "}
                  Smart-contract creation &amp; audit
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="roadmapprog">
                <i>15%</i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="roadmaptxt">
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/roadmap_star.png"
                  alt=""
                />
                <span>
                  Phase <em>02</em>
                </span>
                {/* <h4>Q2 2024</h4> */}
                <p>
                  <i className="fa-solid fa-star" /> CoinSniper.net team
                  verification
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Design elements of P2E game
                  on IOS/Android
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Mass marketing campaign
                  launch with media &amp; news websites
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Presale stages 1-9 commence
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="roadmapprog">
                <i>30%</i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="roadmaptxt">
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/roadmap_star.png"
                  alt=""
                />
                <span>
                  Phase <em>03</em>
                </span>
                {/* <h4>Q3 2024</h4> */}
                <p>
                  <i className="fa-solid fa-star" /> First set of charitable
                  donations
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Continued mass marketing
                  campaign with media partners
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Develop and release $OGARMY
                  token staking system
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="roadmapprog">
                <i>45%</i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="roadmaptxt">
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/roadmap_star.png"
                  alt=""
                />
                <span>
                  Phase <em>04</em>
                </span>
                {/* <h4>Q4 2024</h4> */}
                <p>
                  <i className="fa-solid fa-star" /> OG Army merchandise store
                  launch
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Tier 1 &amp; 2 exchange
                  listings
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Begin development of P2E
                  game on IOS/Android
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="roadmapprog">
                <i>60%</i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="roadmaptxt">
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/roadmap_star.png"
                  alt=""
                />
                <span>
                  Phase <em>05</em>
                </span>
                {/* <h4>Q1-Q2 2025</h4> */}
                <p>
                  <i className="fa-solid fa-star" /> Second set of charitable
                  donations
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Release first set of rare
                  OG Army NFTs (5,000)
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Launch P2E game on
                  IOS/Android
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="roadmapprog">
                <i>75%</i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="roadmaptxt">
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/roadmap_star.png"
                  alt=""
                />
                <span>
                  Phase <em>06</em>
                </span>
                {/* <h4>Q3-Q4 2025 &amp; Behond</h4> */}
                <p>
                  <i className="fa-solid fa-star" /> Continued release of
                  donations to selected charities
                </p>
                <p>
                  <i className="fa-solid fa-star" /> Onboard partnerships &amp;
                  collaborations with gaming influencers
                </p>
                <p>
                  <i className="fa-solid fa-star" /> OG Army public event to
                  raise our cause awareness
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="roadmapprog">
                <i>90%</i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btPics w-100 d-lg-block d-md-none d-sm-none d-none">
        <img
          src="wp-content/themes/ogarmytheme/assets/images/mn.png"
          alt=""
          className="w-100"
        />
      </div>
      <div className="btPics w-100 d-lg-none d-md-block d-sm-block d-block">
        <img
          src="wp-content/themes/ogarmytheme/assets/images/mnmob.png"
          alt=""
          className="w-100"
        />
      </div>
    </section>
  );
}
