import React from "react";

export default function Tokenomics() {
  return (
    <div className="tophead tokenomics">
      <h2>
        $OGARMY
        <br />
        <strong>TOKENOMICS</strong>
      </h2>
      <div className="container">
        <div className="tokenomicsInn tokenmidimg">
          <div className="chrts">
            <div id="chartcontainer" />
          </div>
          <ul className="tokeList">
            <li>
              <i className="fa-solid fa-star" style={{ color: "#5d7c00" }} />
              <strong>Private Sale</strong>
              <br />
              <em>300,000,000</em>
            </li>
            <li>
              <i className="fa-solid fa-star" style={{ color: "#253b07" }} />
              <strong>Public Sale</strong>
              <br />
              <em>3,200,000,000</em>
            </li>
            <li>
              <i className="fa-solid fa-star" style={{ color: "#5d7c00" }} />
              <strong>Development</strong>
              <br />
              <em>1,000,000,000</em>
            </li>
            <li>
              <i className="fa-solid fa-star" style={{ color: "#364c00" }} />
              <strong>Marketing</strong>
              <br />
              <em>2,000,000,000</em>
            </li>
            <li>
              <i className="fa-solid fa-star" style={{ color: "#305e00" }} />
              <strong>Liquidity</strong>
              <br />
              <em>2,000,000,000</em>
            </li>
            <li>
              <i className="fa-solid fa-star" style={{ color: "#3e6000" }} />
              <strong>Ecosystem</strong>
              <br />
              <em>1,000,000,000</em>
            </li>
            <li>
              <i className="fa-solid fa-star" style={{ color: "#729c3e" }} />
              <strong>Team</strong>
              <br />
              <em>500,000,000</em>
            </li>
          </ul>
          {/* <div className="exchTxt">
            <strong>EXCHANGE LISTING PRICE: TBC</strong>
          </div> */}
        </div>
        {/* <div class="tokenmidimg">
  	
      <img src="" alt="" class="w-100 d-lg-block d-md-block d-sm-none d-none" />
      <img src="" alt="" class="w-100 d-lg-none d-md-none d-sm-block d-block"/>
  </div> */}
      </div>
      <span className="tokenimg1">
        <img
          src="wp-content/themes/ogarmytheme/assets/images/tokenomics1.png"
          alt=""
        />
      </span>
      <span className="tokenimg2">
        <img
          src="wp-content/themes/ogarmytheme/assets/images/tokenomics2.png"
          alt=""
        />
      </span>
    </div>
  );
}
