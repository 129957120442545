import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  styled,
} from "@mui/material";
import { useNetwork, useSwitchNetwork } from "wagmi";

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const Dialogbutton = styled(Button)({
  border: "2px solid #00EAEF",
  width: "100%",
  color: "#00EAEF",
  fontWeight: "bold",
});

const NetworkDialog = ({
  setopenChangeNetwork,
  openChangeNetwork,
  connectedChainId,
}) => {
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const handleClose = () => {
    setopenChangeNetwork(false);
  };

  return (
    <div>
      <div className="modal__main__container">
        <Dialog
          open={openChangeNetwork}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent
            className="dialoge__content__section"
            style={{
              background: "#101507",
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                mt={2}
                fontWeight={400}
                color="#ffffff"
                fontSize="20px"
                textAlign="center"
                lineHeight="30px"
              >
                To purchase using{" "}
                {connectedChainId === 1 ? "BNB or USDT" : "ETH"}, <br />
                please click below to switch to the{" "}
                {connectedChainId === 1 ? "BEP-20" : "ERC-20"} network.
              </Box>
            </Box>
            {/* {chains.map((x) => (
              <Box
                key={x?.id}
                align="center"
                mt={3}
                style={{ display: +x.id === +chain?.id ? "none" : "block" }}
                disabled={!switchNetwork || x.id === chain?.id}
              >
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    switchNetwork?.(x.id);
                    setopenChangeNetwork(false);
                  }}
                  className="connect-wall"
                >
                  Switch Network To {x.id === 1 ? "ETH" : "BNB"}
                </a>
              </Box>
            ))} */}
            <Box align="center" mt={3}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  switchNetwork?.(
                    chain?.id === 1 ? 56 : chain?.id === 56 ? 1 : 56
                  );
                  setopenChangeNetwork(false);
                }}
                className="connect-wall"
              >
                Switch Network
              </a>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default NetworkDialog;
