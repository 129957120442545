import React from "react";

export default function CharitySupport() {
  return (
    <div className="tophead charitysec" id="utility">
      <h2>
        CHARITIES WE
        <br />
        <strong>SUPPORT</strong>
      </h2>
      <div className="container">
        <div className="owl-carousel">
          <div className="item">
            <div className="charitybox">
              <div className="charitypara">
                <p>
                  The Save Gorillas foundation trains law enforcement officers
                  and wildlife rangers to combat wildlife crime and poaching
                  efforts aimed at mountain gorillas.{" "}
                </p>
              </div>
              <div className="charitylogo">
                <img src="wp-content/uploads/2022/10/charitylogo.png" alt="" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="charitybox">
              <div className="charitypara">
                <p>
                  Our grassroots projects are keeping gorillas safe from
                  poaching and habitat loss. We also work with local communities
                  to ensure humans and gorillas can live peacefully side-by-side
                  for generations to come.
                </p>
              </div>
              <div className="charitylogo">
                <img src="wp-content/uploads/2022/10/charitylogo2.png" alt="" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="charitybox">
              <div className="charitypara">
                <p>
                  The Dian Fossey Gorilla Fund leads the world in protecting and
                  studying gorillas, while helping communities build their
                  conservation capabilities.
                </p>
              </div>
              <div className="charitylogo">
                <img src="wp-content/uploads/2022/10/charitylogo1.png" alt="" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="charitybox">
              <div className="charitypara">
                <p>
                  The Save Gorillas foundation trains law enforcement officers
                  and wildlife rangers to combat wildlife crime and poaching
                  efforts aimed at mountain gorillas.{" "}
                </p>
              </div>
              <div className="charitylogo">
                <img src="wp-content/uploads/2022/10/charitylogo.png" alt="" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="charitybox">
              <div className="charitypara">
                <p>
                  Our grassroots projects are keeping gorillas safe from
                  poaching and habitat loss. We also work with local communities
                  to ensure humans and gorillas can live peacefully side-by-side
                  for generations to come.
                </p>
              </div>
              <div className="charitylogo">
                <img src="wp-content/uploads/2022/10/charitylogo2.png" alt="" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="charitybox">
              <div className="charitypara">
                <p>
                  The Dian Fossey Gorilla Fund leads the world in protecting and
                  studying gorillas, while helping communities build their
                  conservation capabilities.
                </p>
              </div>
              <div className="charitylogo">
                <img src="wp-content/uploads/2022/10/charitylogo1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
