import React from "react";

export default function Team() {
  return (
    <section
      className="meetteasec tophead"
      id="team"
      style={{
        background:
          "url(wp-content/uploads/2022/10/meettea_bg.jpg) no-repeat top left",
      }}
    >
      <div className="container">
        <h2>
          MEET THE <br /> <strong>ARMY</strong>
        </h2>
        <div className="owl-carousel">
          <div className="item">
            <div className="mettmBox">
              <div className="meetteain">
                <h4>
                  <i className="fa-solid fa-star" /> Kong{" "}
                  <i className="fa-solid fa-star" />
                </h4>
                <span>Founder</span>
                <i className="tmpic">
                  <img src="wp-content/uploads/2022/10/pic2.png" alt="" />
                </i>
              </div>
              {/* <a className="buy-nfts" href="#">
                <i className="fa-solid fa-star" /> READ BIO{" "}
                <i className="fa-solid fa-star" />
              </a> */}
            </div>
          </div>
          <div className="item">
            <div className="mettmBox">
              <div className="meetteain">
                <h4>
                  <i className="fa-solid fa-star" /> Caesar{" "}
                  <i className="fa-solid fa-star" />
                </h4>
                <span>General in Command</span>
                <i className="tmpic">
                  <img src="wp-content/uploads/2022/10/pic3.png" alt="" />
                </i>
              </div>
              {/* <a className="buy-nfts" href="#">
                <i className="fa-solid fa-star" /> READ BIO{" "}
                <i className="fa-solid fa-star" />
              </a> */}
            </div>
          </div>
          <div className="item">
            <div className="mettmBox">
              <div className="meetteain">
                <h4>
                  <i className="fa-solid fa-star" /> Buck{" "}
                  <i className="fa-solid fa-star" />
                </h4>
                <span>Front-line Soldier</span>
                <i className="tmpic">
                  <img src="wp-content/uploads/2022/10/pic4.png" alt="" />
                </i>
              </div>
              {/* <a className="buy-nfts" href="#">
                <i className="fa-solid fa-star" /> READ BIO{" "}
                <i className="fa-solid fa-star" />
              </a> */}
            </div>
          </div>
          <div className="item">
            <div className="mettmBox">
              <div className="meetteain">
                <h4>
                  <i className="fa-solid fa-star" /> Luca{" "}
                  <i className="fa-solid fa-star" />
                </h4>
                <span>Engineer</span>
                <i className="tmpic">
                  <img src="wp-content/uploads/2022/10/pic1.png" alt="" />
                </i>
              </div>
              {/* <a className="buy-nfts" href="#">
                <i className="fa-solid fa-star" /> READ BIO{" "}
                <i className="fa-solid fa-star" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
