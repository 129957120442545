import React from "react";

export default function Mission() {
  return (
    <div className="missionsec" id="mission">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="numberbox">
              <i>
                <img src="wp-content/uploads/2022/10/mission1.png" alt="" />
              </i>
              <strong>8,000+</strong>
              <h5>Great apes are poached every year</h5>{" "}
              <span>
                <img src="wp-content/uploads/2022/10/missionstar.png" alt="" />
              </span>
              <p>
                Over 8,000 apes are poached every year, which are either stolen
                or illegally killed and sold for trade.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="numberbox">
              <i>
                <img src="wp-content/uploads/2022/10/mission2.png" alt="" />
              </i>
              <strong>$1bn+</strong>
              <h5>Estimated value of the illegal ape trade</h5>{" "}
              <span>
                <img src="wp-content/uploads/2022/10/missionstar.png" alt="" />
              </span>
              <p>
                An astonishing amount of money is generated through illegal
                poach trade, and we're here to stop it.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="numberbox">
              <i>
                <img src="wp-content/uploads/2022/10/mission3.png" alt="" />
              </i>
              <strong>70%</strong>
              <h5>Of Eastern gorillas wiped out in 20 years</h5>{" "}
              <span>
                <img src="wp-content/uploads/2022/10/missionstar.png" alt="" />
              </span>
              <p>
                The Eastern Gorilla has seen the biggest decline with 70% of the
                population being killed in 20 years.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="numberbox">
              <i>
                <img
                  src="wp-content/uploads/2023/03/ArrowsIcon_Green.png"
                  alt=""
                />
              </i>
              <strong>2030</strong>
              <h5>Estimated date apes could become exctinct</h5>{" "}
              <span>
                <img src="wp-content/uploads/2022/10/missionstar.png" alt="" />
              </span>
              <p>
                If nothing more is done to prevent illegal trade, apes could die
                out in less than 7 years.
              </p>
            </div>
          </div>
        </div>
        <div className="tophead themission">
          <i>
            <img
              src="wp-content/themes/ogarmytheme/assets/images/missioncap.png"
              alt=""
            />
          </i>
          <h2>
            THE
            <br />
            <strong>MISSION</strong>
          </h2>
          <p>
            OG Army is on a mission to end all threats of extinction to our ape
            family. <br />
            From stopping poachers to protecting their wildlife, we'll fight it.
          </p>
        </div>
        <a href="#buy" className="missionarrow">
          <img
            src="wp-content/themes/ogarmytheme/assets/images/missionarrow.png"
            alt=""
          />
        </a>
        <span className="leafone">
          <img
            src="wp-content/themes/ogarmytheme/assets/images/tree.png"
            alt=""
          />
        </span>
        <span className="leaftwo">
          <img
            src="wp-content/themes/ogarmytheme/assets/images/tree1.png"
            alt=""
          />
        </span>
        <span className="middleimg">
          <img
            src="wp-content/themes/ogarmytheme/assets/images/missiongun.png"
            alt=""
          />
        </span>
      </div>
    </div>
  );
}
