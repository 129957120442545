import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  Box,
  Dialog,
  InputBase,
  Slide,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { formatUnits, parseUnits } from "viem";
// import { formatUnits, parseUnits } from "@ethersproject/units";
import { AppContext } from "../utils";
import {
  bscPresaleAddress,
  contractAddress,
  ethPresaleAddress,
} from "../ConnectivityAssets/environment";
import { HashLoader, RingLoader } from "react-spinners";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import { bnb, logo, usdt, eth } from "../components/SmallComponents/Images";
import {
  bnbPresaleReadFunction,
  bnbPresaleWriteFunction,
  bnbUsdtReadFunction,
  bnbUsdtWriteFunction,
  ethPresaleReadFunction,
  ethPresaleWriteFunction,
  ethUsdtReadFunction,
  ethUsdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import { fetchBalance } from "@wagmi/core";
// import { StyledButton } from "../Components/SmallComponents/AppComponents";
// import { bnb, logo, usdt, eth } from "../Components/SmallComponents/Images";
// import ethPresaleAbi from "../ConnectivityAssets/ethPreSaleAbi.json";
// import bnbPresaleAbi from "../ConnectivityAssets/bnbPresaleAbi.json";
// import Web3 from "web3";
// import {
//   bscProvider,
//   ethProvider,
//   getContract,
//   voidSigners,
// } from "../ConnectivityAssets/multiNetwork";
// import { Contract } from "ethers";
import NetworkDialog from "../NetworkDialog";
import TimerCountDown from "../Timer";
import moment from "moment";
import { ToastNotify } from "../ConnectivityAssets/hooks";
import Loading from "../components/SmallComponents/loading";
import {
  bnbPresaleMultiReadFunction,
  bnbPresaleMultiWriteFunction,
  ethPresaleMultiReadFunction,
  ethPresaleMultiWriteFunction,
} from "../ConnectivityAssets/multiNetwork";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    height: "40px",
    fontSize: "20px",
    fontWeight: "500",
    fontFamily: "Monroe-Regular",
    textAlign: "left",
    color: "#ffffff",
    backgroundColor: "transparent",
    paddingLeft: "15px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});

/// handle number field

const handleKeyDown = (event) => {
  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    event.preventDefault();
  }
};
const handleWheel = (e) => {
  e.target.blur();
};
/// end handle number field
export default function MainSection() {
  let currentTime = moment().format("X");
  let startTime = 1711886400;
  const matches = useMediaQuery("(max-width:700px)");
  const { account, connectedChainId } = useContext(AppContext);
  const { open } = useWeb3Modal();

  const [token, setToken] = useState("BNBUSDT");
  const [openDialog, setOpenDialog] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState("");
  const [receivedTokens, setreceivedTokens] = useState("");
  const [totalSoldTokens, settotalSoldTokens] = useState(0);
  const [totalSupply, settotalSupply] = useState(0);
  const [totalSupplyPerPhase, settotalSupplyPerPhase] = useState(0);
  const [balanceETH, setbalanceETH] = useState(0);
  const [userPurchasedTokens, setuserPurchasedTokens] = useState(0);
  const [userPuchasedTokensToShow, setuserPuchasedTokensToShow] = useState(0);
  const [userPurchasedTokensETH, setuserPurchasedTokensETH] = useState();
  const [oneUSDTtoToken, setoneUSDTtoToken] = useState(0);
  const [oneETHtoToken, setoneETHtoToken] = useState(0);
  const [oneEthUsdtToToken, setOneEthUsdtToToken] = useState(0);
  const [oneBNBtoToken, setoneBNBtoToken] = useState(0);
  const [nextStagePrice, setnextStagePrice] = useState(0);
  const [presaleStage, setpresaleStage] = useState(0);
  const [web3ChainId, setweb3ChainId] = useState(0);
  const [hardCap, sethardCap] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [openNetworkChange, setopenNetworkChange] = useState(false);
  const [presaleContract, setpresaleContract] = useState("");
  const [usdtContract, setusdtContract] = useState("");
  const [providerRPC, setproviderRPC] = useState("");
  const [balanceUSDT, setbalanceUSDT] = useState(0);
  const [balanceBNB, setbalanceBNB] = useState(0);
  const [balanceUSDTEth, setbalanceUSDTEth] = useState(0);
  const [loading, setloading] = useState(false);
  const [loadingMain, setloadingMain] = useState(false);
  const [isClaimEnabled, setisClaimEnabled] = useState(false);
  const [raisedAmount, setRaisedAmount] = useState(0);
  const [callFunction, setCallFunction] = useState(true);
  const [complete, setComplete] = useState(false);
  const [progressBar, setProgessBar] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    (async () => {
      try {
        const nsp = await bnbPresaleMultiReadFunction("nextPrice");
        setnextStagePrice(+formatUnits(nsp.toString(), 18));
        const saleStage = await bnbPresaleMultiReadFunction("StageCount");
        setpresaleStage(+saleStage.toString());

        let perPhaseSupplyETH = await ethPresaleMultiReadFunction(
          "tokenForSell"
        );
        settotalSupplyPerPhase(+formatUnits(perPhaseSupplyETH.toString(), 18));
        let price = await bnbPresaleMultiReadFunction("tokenPerUsd");
        settokenPrice(+formatUnits(price.toString(), 18));
        price = +formatUnits(price.toString(), 18);
        const rec1 = await bnbPresaleMultiReadFunction("usdtToToken", [
          "1000000000000000000",
        ]);
        setoneUSDTtoToken(+formatUnits(rec1.toString(), 18));
        const rec2 = await ethPresaleMultiReadFunction("NativeToToken", [
          "1000000000000000000",
        ]);
        setoneETHtoToken(+formatUnits(rec2.toString(), 18));
        const rec3 = await bnbPresaleMultiReadFunction("NativeToToken", [
          "1000000000000000000",
        ]);
        setoneBNBtoToken(+formatUnits(rec3.toString(), 18));
        const rec4 = await ethPresaleMultiReadFunction("usdtToToken", [
          "1000000000000000000",
        ]);
        setOneEthUsdtToToken(+formatUnits(rec4.toString(), 18));
        const supplyTotal = await ethPresaleMultiReadFunction("totalSupply");
        const soldTotalETH = await ethPresaleMultiReadFunction("soldToken");
        const soldTotalBNB = await bnbPresaleMultiReadFunction("soldToken");

        let total =
          +formatUnits(soldTotalETH.toString(), 18) +
          +formatUnits(soldTotalBNB.toString(), 18);
        total = parseFloat(total).toFixed(0);
        settotalSoldTokens(toLocalFormat(total));
        let hardCap = +formatUnits(supplyTotal.toString(), 18) / +price;
        hardCap = parseFloat(hardCap).toFixed(0);
        sethardCap(toLocalFormat(hardCap));
        let supp = +formatUnits(supplyTotal.toString(), 18);
        supp = parseFloat(supp).toFixed(0);
        settotalSupply(toLocalFormat(supp));

        //     /////////// Raised Total

        const raised = await bnbPresaleMultiReadFunction("amountRaised");
        let usdtRaisedBSC = await bnbPresaleMultiReadFunction(
          "amountRaisedUSDT"
        );
        usdtRaisedBSC = +formatUnits(usdtRaisedBSC.toString(), 18);

        const currentPriceBNB = await bnbPresaleMultiReadFunction(
          "getLatestPrice"
        );

        let bnbToUsdt =
          +formatUnits(raised.toString(), 18) *
          +formatUnits(currentPriceBNB.toString(), 8);
        let usdtRaisedEth = await ethPresaleMultiReadFunction(
          "amountRaisedUSDT"
        );
        usdtRaisedEth = +formatUnits(usdtRaisedEth.toString(), 6);
        const ethRaised = await ethPresaleMultiReadFunction("amountRaised");
        const ethPrice = await ethPresaleMultiReadFunction("getLatestPrice");
        let ethToUsdt =
          +formatUnits(ethRaised.toString(), 18) *
          +formatUnits(ethPrice.toString(), 8);

        let totalRaised =
          +ethToUsdt + +bnbToUsdt + +usdtRaisedBSC + +usdtRaisedEth;
        totalRaised = parseFloat(totalRaised).toFixed(0);
        setRaisedAmount(toLocalFormat(totalRaised));
        const claimEnabled = await bnbPresaleMultiReadFunction("enableClaim");
        setisClaimEnabled(claimEnabled);
        if (account) {
          const userBNB = await bnbPresaleMultiReadFunction("users", [account]);
          const userETH = await ethPresaleMultiReadFunction("users", [account]);
          let totalToken =
            +formatUnits(userBNB[2].toString(), 18) +
            +formatUnits(userETH[1].toString(), 18);
          totalToken = parseFloat(totalToken).toFixed(0);
          setuserPuchasedTokensToShow(toLocalFormat(totalToken));
        }
        setCallFunction(false);
      } catch (error) {
        setCallFunction(false);
        console.log(error, "ERROR VoidSigner Data");
      }
    })();
  }, [account, callFunction]);

  useEffect(() => {
    if (account) {
      (async () => {
        try {
          if (+connectedChainId === 56) {
            const bnbBal = await fetchBalance({
              address: account,
            });
            let balBNB = parseFloat(
              +formatUnits(bnbBal?.value?.toString(), 18)
            ).toFixed(4);
            setbalanceBNB(toLocalFormat(+balBNB));
            const balance = await bnbUsdtReadFunction("balanceOf", [account]);
            let usdtBal = parseFloat(
              +formatUnits(balance.toString(), 18)
            ).toFixed(2);
            setbalanceUSDT(toLocalFormat(+usdtBal));

            const user = await bnbPresaleReadFunction("users", [account]);
            setuserPurchasedTokens(
              toLocalFormat(+formatUnits(user[2].toString(), 18))
            );

            const purchasedWL = await bnbPresaleReadFunction("wallets", [
              account,
            ]);
            setuserPurchasedTokensETH(+formatUnits(purchasedWL.toString(), 18));
          }
          if (+connectedChainId === 1) {
            const walletBalance = await fetchBalance({
              address: account,
            });
            let ethBal = parseFloat(
              +formatUnits(walletBalance?.value?.toString(), 18)
            ).toFixed(4);
            setbalanceETH(toLocalFormat(+ethBal));

            const balance = await ethUsdtReadFunction("balanceOf", [account]);
            let usdtBalEth = parseFloat(
              +formatUnits(balance.toString(), 6)
            ).toFixed(2);
            setbalanceUSDTEth(toLocalFormat(+usdtBalEth));

            const user = await ethPresaleReadFunction("users", [account]);
            let userTokens = parseFloat(
              +formatUnits(user[1].toString(), 18)
            ).toFixed(2);
            setuserPurchasedTokens(toLocalFormat(userTokens));
          }
          setCallFunction(false);
        } catch (error) {
          setCallFunction(false);
          console.log(error);
        }
      })();
    }
  }, [account, connectedChainId, callFunction]);

  const buyHadler = async () => {
    if (account) {
      if (!enteredAmount) {
        setAlertState({
          open: true,
          message: `Error! Please enter a amount`,
          severity: "error",
        });
      } else if (enteredAmount <= 0) {
        setAlertState({
          open: true,
          message: `Error! Please enter a valid amount`,
          severity: "error",
        });
      } else if (+currentTime > +startTime) {
        setAlertState({
          open: true,
          message: `Error! Please wait for the next stage`,
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          if (connectedChainId === 56) {
            if (token === "BNBUSDT") {
              let allowance = await bnbUsdtReadFunction("allowance", [
                account,
                bscPresaleAddress,
              ]);
              if (+formatUnits(allowance.toString(), 18) < +enteredAmount) {
                await bnbUsdtWriteFunction("approve", [
                  bscPresaleAddress,
                  "9999999999999999999999999999999",
                ]);
              }
              await bnbPresaleWriteFunction("buyTokenUSDT", [
                parseUnits(enteredAmount.toString(), 18),
              ]);
              setComplete(true);
            } else {
              await bnbPresaleWriteFunction(
                "buyToken",
                [],
                parseUnits(enteredAmount.toString(), 18).toString()
              );
              setComplete(true);
            }
          } else {
            if (token === "ETHUSDT") {
              await ethUsdtWriteFunction("approve", [
                ethPresaleAddress,
                parseUnits(enteredAmount.toString(), 6),
              ]);
              await ethPresaleWriteFunction("buyTokenUSDT", [
                parseUnits(enteredAmount.toString(), 6),
              ]);
              setComplete(true);
            } else {
              await ethPresaleWriteFunction(
                "buyToken",
                [],
                parseUnits(enteredAmount.toString(), 18).toString()
              );

              setComplete(true);
            }
          }

          setEnteredAmount("");
          setCallFunction(true);
          // initProgress();
          setloading(false);
        } catch (error) {
          setloading(false);
          if (error?.data?.message) {
            setAlertState({
              open: true,
              message: error?.data?.message,
              severity: "error",
            });
          } else if (error?.reason) {
            setAlertState({
              open: true,
              message: error?.reason,
              severity: "error",
            });
          } else {
            setAlertState({
              open: true,
              message: error?.shortMessage,
              severity: "error",
            });
          }
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };
  useEffect(() => {
    const calculator = async () => {
      try {
        if (token === "BNBUSDT") {
          let tokenUSDT = +oneUSDTtoToken * +enteredAmount;
          setreceivedTokens(tokenUSDT);
        } else if (token === "ETH") {
          let tokenEth = +oneETHtoToken * +enteredAmount;
          setreceivedTokens(tokenEth);
        } else if (token === "ETHUSDT") {
          // let tokenEthUSDT = +oneEthUsdtToToken * +enteredAmount;
          let tokenEthUSDT = +oneUSDTtoToken * +enteredAmount;

          setreceivedTokens(tokenEthUSDT);
        } else {
          let tokenbnb = +oneBNBtoToken * +enteredAmount;
          setreceivedTokens(tokenbnb);
        }
      } catch (error) {}
    };
    if (+enteredAmount > 0) {
      calculator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredAmount, token]);

  useEffect(() => {
    (async () => {
      try {
        if (+connectedChainId === 56) {
          const totalSold = await bnbPresaleMultiReadFunction("soldToken");
          const supply = await bnbPresaleMultiReadFunction("totalSupply");
          let prog =
            (+formatUnits(totalSold.toString(), 18) /
              +formatUnits(supply.toString(), 18)) *
            100;
          setProgessBar(+prog);
        }
        if (+connectedChainId === 1) {
          const totalSold = await ethPresaleMultiReadFunction("soldToken");
          const supply = await ethPresaleMultiReadFunction("totalSupply");
          let prog =
            (+formatUnits(totalSold.toString(), 18) /
              +formatUnits(supply.toString(), 18)) *
            100;
          setProgessBar(+prog);
        }
        setCallFunction(false);
      } catch (error) {
        setCallFunction(false);
        console.log(error);
      }
    })();
  }, [connectedChainId, callFunction]);

  const claimHandler = async () => {
    if (account) {
      if (!isClaimEnabled) {
        setAlertState({
          open: true,
          message: `You can claim tokens after presale will end.`,
          severity: "error",
        });
      } else {
        try {
          setloadingMain(true);
          if (+userPurchasedTokens > 0) {
            await bnbPresaleMultiWriteFunction("claimTokens");
          }
          if (+userPurchasedTokensETH > 0) {
            const tx = await ethPresaleMultiWriteFunction("ClaimForEth");
            await tx.wait();
          }
          setloadingMain(false);
          setComplete(true);
          setAlertState({
            open: true,
            message: `Transection Completed!`,
            severity: "success",
          });
          setEnteredAmount("");
          setCallFunction(true);
        } catch (error) {
          setloadingMain(false);
          if (error?.data?.message) {
            setAlertState({
              open: true,
              message: error?.data?.message,
              severity: "error",
            });
          } else if (error?.reason) {
            setAlertState({
              open: true,
              message: error?.reason,
              severity: "error",
            });
          } else {
            setAlertState({
              open: true,
              message: error?.shortMessage,
              severity: "error",
            });
          }
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };
  return (
    <div className="bannersec" id="banner">
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loadingMain} />
      <NetworkDialog
        openChangeNetwork={openNetworkChange}
        setopenChangeNetwork={setopenNetworkChange}
        connectedChainId={connectedChainId}
      />
      <Dialog
        // fullWidth
        open={openDialog}
        TransitionComponent={Transition}
        sx={{
          zIndex: 10,
          "& .MuiPaper-root": {
            backgroundColor: "#101507",
            width: matches ? "100%" : "30%",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            py={2}
            pl={3}
            pr={1}
            sx={{
              backgroundImage:
                "linear-gradient(90deg, rgba(204,238,19,1) 30%, rgba(163,181,81,1) 100%)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              fontSize="22px"
              fontFamily="Monroe-Regular"
              fontWeight="700"
              sx={{ fontSize: "18px" }}
            >
              Exchange
            </Box>
            <CloseIcon
              onClick={() => {
                setEnteredAmount("");
                setOpenDialog(!openDialog);
                setComplete(false);
              }}
              style={{
                cursor: "pointer",
                color: "#000000",
              }}
            />
          </Box>
          {loading ? (
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              py={3}
            >
              <HashLoader color="#ffffff" size={170} />
              <Box
                textAlign="center"
                mt={6}
                fontSize="15px"
                fontWeight="600"
                color="#ffffff"
                fontFamily="Monroe-Regular"
              >
                YOUR TRANSACTION IS IN PROGRESS <br />
                PLEASE WAIT
              </Box>
            </Box>
          ) : complete ? (
            <Box p={2} textAlign="center" color="#fff">
              <CheckCircleIcon sx={{ color: "#57CA5C", fontSize: "100px" }} />
              <Typography
                fontFamily="Monroe-Regular"
                color="lightGray"
                variant="body1"
                fontWeight="600"
              >
                YOUR PURCHASE HAS BEEN SUCCESSFUL!
              </Typography>
            </Box>
          ) : (
            <Box py={3} px={2}>
              <Typography
                variant="body1"
                fontSize="15px"
                fontWeight="400"
                color="lightGray"
                textAlign="right"
                fontFamily="Monroe-Regular"
              >
                Balance:{" "}
                {token === "BNBUSDT"
                  ? balanceUSDT
                  : token === "ETH"
                  ? balanceETH
                  : token === "ETHUSDT"
                  ? balanceUSDTEth
                  : balanceBNB}
              </Typography>
              <Box
                sx={{
                  border: "1px solid #212529",
                  borderRadius: "8px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                mt={1}
                pr={1}
              >
                <TextInput
                  fullWidth
                  placeholder="Enter amount"
                  type="number"
                  onKeyDown={handleKeyDown}
                  onWheel={handleWheel}
                  value={enteredAmount}
                  onChange={(e) => setEnteredAmount(e.target.value)}
                />

                <img
                  src={
                    token === "BNBUSDT" || token === "ETHUSDT"
                      ? usdt
                      : token === "BNB"
                      ? bnb
                      : eth
                  }
                  alt=""
                  style={{
                    marginRight: "4px",
                    width: "35px",
                  }}
                />
              </Box>
              <Box mt={2}>
                <Box
                  sx={{
                    border: "1px solid #212529",
                    borderRadius: "8px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  mb={2}
                  mt={1}
                  pr={1}
                >
                  <TextInput
                    fullWidth
                    placeholder="You will get"
                    type="number"
                    onKeyDown={handleKeyDown}
                    onWheel={handleWheel}
                    value={
                      +enteredAmount > 0
                        ? parseFloat(receivedTokens).toFixed(2)
                        : ""
                    }
                  />
                  <img
                    src={logo}
                    alt=""
                    style={{
                      marginRight: "4px",
                      width: "35px",
                    }}
                  />
                </Box>
              </Box>
              <StyledButton width="100%" onClick={() => buyHadler()}>
                Buy now
              </StyledButton>
            </Box>
          )}
        </Box>
      </Dialog>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="banner_box">
              <img
                src="wp-content/uploads/2022/10/banner.jpg"
                alt=""
                className="w-100 d-lg-block d-md-block d-sm-none d-none"
              />
              {/* <img src="https://ogarmy.io/wp-content/uploads/2022/10/bannermob.jpg" alt="" class="w-100 d-lg-none d-md-none d-sm-block d-block" /> */}
              <div className="banner_innr">
                <div className="container">
                  <div className="banner_details">
                    <strong>APE INTO $OGARMY</strong>
                    <b>
                      <em>Invest, Hunt &amp; Play 2 Earn</em>
                    </b>
                    <p>
                      The world's first P2E crypto coin dedicated to fighting
                      against ape extinction.
                      <br />
                      <br />
                      Play a series of action packed ape-themed games and earn
                      crypto now!
                    </p>
                    <ul className="readsosul">
                      <li>
                        <a
                          href="whitepaper.pdf"
                          className="read-white"
                          target="_blank"
                        >
                          <i className="fa-solid fa-star" /> Read Whitepaper{" "}
                          <i className="fa-solid fa-star" />
                        </a>
                      </li>
                      <li>
                        <ul className="sos_icons">
                          <li>
                            <a
                              href="https://t.me/ogarmycoin"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fa-brands fa-telegram" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/OGArmyCoin"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fa-brands fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://discord.gg/6zFBDkUJh6"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fa-brands fa-discord" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/ogarmycoin/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fa-brands fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fa-brands fa-facebook-f" />
                            </a>
                          </li>
                        </ul>
                        <a href="#" className="joinbtn">
                          Join Us Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="presaleclpbx" id="presBox">
                <span>
                  <i className="fa-solid fa-star" /> BUY $OGARMY{" "}
                  <i className="fa-solid fa-star" />
                </span>
                <h4>STAGE {presaleStage > 0 ? presaleStage : "Loading..."}</h4>
                <strong>
                  1 $OGARMY = ${+tokenPrice > 0 ? 1 / +tokenPrice : 0}
                </strong>
                <p>
                  Hurry before next stage <br /> price increases to $
                  {+nextStagePrice > 0 ? 1 / +nextStagePrice : "Loading..."}
                </p>
                <ul>
                  <li>
                    Sold <br />
                    <u>
                      {+totalSoldTokens.length > 2
                        ? totalSoldTokens
                        : "Loading..."}
                    </u>
                    <br />{" "}
                    {+totalSupply.length > 2 ? totalSupply : "Loading..."}
                  </li>
                  <li>
                    Raised <br />{" "}
                    <u>
                      ${+raisedAmount.length > 2 ? raisedAmount : "Loading..."}
                    </u>
                    <br /> ${+hardCap.length > 2 > 0 ? hardCap : "Loading..."}
                  </li>
                </ul>
                {isClaimEnabled && connectedChainId === 56 ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => claimHandler()}
                    className="connect-wall"
                  >
                    Claim OGARMY
                  </a>
                ) : null}
                {account && !isClaimEnabled ? (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {connectedChainId === 56 ? (
                      <>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setToken("BNBUSDT");
                            setOpenDialog(true);
                          }}
                          className="connect-wall"
                        >
                          Buy with USDT
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setToken("BNB");
                            setOpenDialog(true);
                          }}
                          className="connect-wall"
                        >
                          Buy With BNB
                        </a>
                      </>
                    ) : (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setToken("ETH");
                            setOpenDialog(true);
                          }}
                          className="connect-wall"
                        >
                          Buy With ETH
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setToken("ETHUSDT");
                            setOpenDialog(true);
                          }}
                          className="connect-wall"
                        >
                          Buy With USDT
                        </a>
                      </>
                    )}
                  </>
                ) : (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={async () => await open()}
                    className="connect-wall"
                  >
                    CONNECT WALLET
                  </a>
                )}
                <p
                  style={{
                    textDecoration: "underline",
                    marginBottom: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => setopenNetworkChange(true)}
                >
                  Change network to{" "}
                  {connectedChainId === 1 ? "BEP-20" : "ERC-20"}
                </p>
                <p>
                  Your purchased $OGARMY: <br />{" "}
                  <em>{userPuchasedTokensToShow}</em>
                </p>

                <TimerCountDown time={startTime} nextPrice={nextStagePrice} />

                <Box mb={5} />
              </div>
              <div className="mobgrup w-100 d-lg-none d-md-none d-sm-block d-block">
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/banmb1.png"
                  alt=""
                  className="w-100"
                />
                <span className="mobflg">
                  <img
                    src="wp-content/themes/ogarmytheme/assets/images/flg.png"
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
