import React from "react";

export default function HowToBuy() {
  return (
    <section
      className="howtobuysec tophead"
      style={{
        background:
          "url(wp-content/uploads/2022/10/team4b.jpg) no-repeat top left",
      }}
    >
      <div className="container" id="buy">
        <h2>
          how to <br /> <strong>buy</strong>
        </h2>
        <div className="row">
          <div className="col-md-4">
            <div className="howtobuyin">
              <span className="tagstp">
                STEP <em>01</em>
              </span>
              <h4>
                Connect your <br /> <strong>wallet</strong>
              </h4>
              <div className="howtobuybtmimg">
                <img src="wp-content/uploads/2022/10/hby_hnd1.png" alt="" />
              </div>
              <a
                href="#url"
                data-toggle="modal"
                data-target="#exampleModal1"
                className="moreplslrn"
              >
                <span className="morepls">
                  <img
                    src="wp-content/themes/ogarmytheme/assets/images/plus.png"
                    alt=""
                  />
                </span>
                <span className="learn-more">LEARN MORE</span>
              </a>
            </div>
          </div>
          {/* Modal */}
          <div className="col-md-4">
            <div className="howtobuyin">
              <span className="tagstp">
                STEP <em>02</em>
              </span>
              <h4>
                Buy with <strong>ETH</strong>, <strong>BNB</strong> or{" "}
                <strong>USDT</strong>
              </h4>
              <div className="howtobuybtmimg">
                <img src="wp-content/uploads/2022/10/hby_hnd2.png" alt="" />
              </div>
              <a
                href="#url"
                data-toggle="modal"
                data-target="#exampleModal2"
                className="moreplslrn"
              >
                <span className="morepls">
                  <img
                    src="wp-content/themes/ogarmytheme/assets/images/plus.png"
                    alt=""
                  />
                </span>
                <span className="learn-more">LEARN MORE</span>
              </a>
            </div>
          </div>
          {/* Modal */}
          <div className="col-md-4">
            <div className="howtobuyin">
              <span className="tagstp">
                STEP <em>03</em>
              </span>
              <h4>
                Receive tokens to <br /> <strong>your wallet</strong>
              </h4>
              <div className="howtobuybtmimg">
                <img src="wp-content/uploads/2022/10/hby_hnd3.png" alt="" />
              </div>
              <a
                href="#url"
                data-toggle="modal"
                data-target="#exampleModal3"
                className="moreplslrn"
              >
                <span className="morepls">
                  <img
                    src="wp-content/themes/ogarmytheme/assets/images/plus.png"
                    alt=""
                  />
                </span>
                <span className="learn-more">LEARN MORE</span>
              </a>
            </div>
          </div>
          {/* Modal */}
        </div>
      </div>
      <span className="howtshw">
        <img
          src="wp-content/themes/ogarmytheme/assets/images/shw.png"
          alt=""
          className="w-100"
        />
      </span>
    </section>
  );
}
