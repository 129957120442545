import React from "react";

export default function StepsToBuy() {
  return (
    <>
      <div
        className="modal fade modalpop"
        id="exampleModal1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body tophead">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/close2.png"
                  alt=""
                />
              </button>
              <div className="pop_top">
                <h3>
                  Step
                  <br /> <strong>01</strong>
                </h3>
                <h2>
                  Connect your <strong>wallet</strong>
                </h2>
              </div>
              <div className="pop_mid">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="pop_midBox">
                      <p>
                        Click the “Buy Now” button on our website and you will
                        be prompted to connect your wallet.
                      </p>
                      <p>You will have 2 options:</p>
                      <p>
                        1. Connect with MetaMask
                        <br />
                        2. Connect with WalletConnect
                      </p>
                      <p>
                        Both options are 100% secure and you can choose your
                        preference.
                      </p>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade modalpop"
        id="exampleModal2"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body tophead">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/close2.png"
                  alt=""
                />
              </button>
              <div className="pop_top">
                <h3>
                  Step
                  <br /> <strong>02</strong>
                </h3>
                <h2>
                  BUY WITH <strong>ETH</strong>, <strong>BNB</strong> or{" "}
                  <strong>USDT</strong>
                </h2>
              </div>
              <div className="pop_mid">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="pop_midBox">
                      <p>
                        <b>Buying $OGARMY with ETH</b>
                      </p>
                      <p>
                        Load ETH into your wallet, ECR-20 or BEP-20. Connect
                        your wallet and click the “Buy with ETH” button to swap
                        ETH for $OGARMY. Enter the amount of ETH you’d like to
                        invest to see how many $OGARMY tokens you will receive,
                        then click swap.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="pop_midBox">
                      <p>
                        <b>Buying $OGARMY with BNB</b>
                      </p>
                      <p>
                        Load BNB into your wallet. Connect your wallet and click
                        the “Buy with BNB” button to swap BNB for $OGARMY. Enter
                        the amount of BNB you’d like to invest to see how many
                        $OGARMY tokens you will receive, then click swap.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="pop_midBox">
                      <p>
                        <b>Buying $OGARMY with USDT</b>
                      </p>
                      <p>
                        Load USDT into your wallet, ECR-20 or BEP-20. Connect
                        your wallet and click the “Buy with USDT” button to swap
                        USDT for $OGARMY. Enter the amount of USDT you’d like to
                        invest to see how many $OGARMY tokens you will receive,
                        then click swap.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade modalpop"
        id="exampleModal3"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body tophead">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img
                  src="wp-content/themes/ogarmytheme/assets/images/close2.png"
                  alt=""
                />
              </button>
              <div className="pop_top">
                <h3>
                  STEP
                  <br /> <strong>03</strong>
                </h3>
                <h2>
                  Receive tokens to <strong>your wallet</strong>
                </h2>
              </div>
              <div className="pop_mid">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="pop_midBox">
                      <p>
                        <b>Welcome to the Army.</b>
                      </p>
                      <p>
                        You’ll now be enrolled to OG Army as a proud holder of
                        $OGARMY tokens.
                      </p>
                      <p>
                        Once the presale has finished, you will be able to
                        unlock your tokens via our website. You can then trade
                        them via tier 1 &amp; 2 exchanges.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
