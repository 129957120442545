import React from "react";

export default function AsFeaturedIn() {
  return (
    <div className="featubox">
      <div className="container">
        <span>
          <strong className="fearts">
            <em>As</em> <i>Featured</i> <em>In</em>
          </strong>
          <br />
          <a href="#mission">
            <img
              src="wp-content/themes/ogarmytheme/assets/images/feat_arw.png"
              alt=""
            />
          </a>
        </span>
        <div className="owl-carousel">
          <div className="item">
            <div className="featuboxin">
              <img src="wp-content/uploads/2023/03/anylogo-1.png" alt="" />
            </div>
          </div>
          <div className="item">
            <div className="featuboxin">
              <img src="wp-content/uploads/2022/10/feati2.png" alt="" />
            </div>
          </div>
          <div className="item">
            <div className="featuboxin">
              <img src="wp-content/uploads/2023/03/timelogo-1.png" alt="" />
            </div>
          </div>
          <div className="item">
            <div className="featuboxin">
              <img src="wp-content/uploads/2022/10/feati4.png" alt="" />
            </div>
          </div>
          <div className="item">
            <div className="featuboxin">
              <img src="wp-content/uploads/2023/03/crylogo-1.png" alt="" />
            </div>
          </div>
          <div className="item">
            <div className="featuboxin">
              <img src="wp-content/uploads/2022/10/feati6.png" alt="" />
            </div>
          </div>
          <div className="item">
            <div className="featuboxin">
              <img src="wp-content/uploads/2022/10/feati2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
