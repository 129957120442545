import React from "react";

export default function Footer() {
  return (
    <footer className="footersec">
      <div className="container">
        <a href="index.html">
          <img src="wp-content/uploads/2022/10/ftr_logo.png" alt="" />
        </a>
        <h4>
          {" "}
          JOIN OUR <br /> SOCIAL CHANNELS
        </h4>
        <div className="sosbx">
          <ul className="ftr_sos">
            <li>
              <a href="#" target="_blank">
                <i className="fa-brands fa-facebook-f" />
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i className="fa-brands fa-youtube" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/OGArmyCoin" target="_blank">
                <i className="fa-brands fa-twitter" />
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i className="fa-brands fa-instagram" />
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i className="fa-brands fa-discord" />
              </a>
            </li>
          </ul>
          <ul className="leftlist">
            <li>© OG ARMY | 2024</li>
          </ul>
          <ul className="rightlist">
            <li>
              <a href="/TandC.pdf" target="_blank">
                TERMS
              </a>
            </li>
            <li>
              <a
                href="https://www.ogarmy.io/privacy-policy.pdf"
                target="_blank"
              >
                PRIVACY POLICY
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="tgpic">
        <img
          src="wp-content/themes/ogarmytheme/assets/images/bach.png"
          alt=""
          className="w-100"
        />
      </div>
    </footer>
  );
}
