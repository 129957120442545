import React from "react";

export default function Artilitary() {
  return (
    <section
      className="artilisec tophead"
      style={{
        background:
          "url(wp-content/uploads/2022/10/artili_ban.jpg) no-repeat top left",
      }}
    >
      <div className="container">
        <h2>
          OG Army’s
          <br />
          <strong>Artilitary</strong>
        </h2>
        <div className="row">
          <div className="col-md-3">
            <div className="artiliin">
              <div className="pics">
                <img src="wp-content/uploads/2022/10/artili_rw1.png" alt="" />
              </div>
              <div className="artiliin_inn">
                <h4>
                  Rare
                  <br />
                  NFTs
                </h4>
                <p>
                  Limited NFT Collection are the best so far. The full
                  collection of 100,000 will be released soon, including
                  Ultra-rare OG's which provide exclusive benefits.
                </p>
              </div>
              <div className="text-center">
                <a className="buy-nfts" href="#">
                  <i className="fa-solid fa-star" /> BUY NFTs
                  <i className="fa-solid fa-star" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="artiliin">
              <div className="pics">
                <img src="wp-content/uploads/2022/10/artili_rw2a.png" alt="" />
              </div>
              <div className="artiliin_inn">
                <h4>
                  Coin <br /> Staking
                </h4>
                <p>
                  The OG Military Retreat staking system will provide all OG
                  soldiers the ability to receive free $OGARMY coins for
                  supporting our mission long-term.
                </p>
              </div>
              <div className="text-center">
                <a className="buy-nfts" href="#">
                  <i className="fa-solid fa-star" /> BUY COINS
                  <i className="fa-solid fa-star" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="artiliin">
              <div className="pics">
                <img src="wp-content/uploads/2022/10/artili_rw3a.png" alt="" />
              </div>
              <div className="artiliin_inn">
                <h4>
                  P2E <br /> Games
                </h4>
                <p>
                  Action packed ape-themed P2E games. The first of our series,
                  OG Army: The Beginning of a Rebellion, is being released on
                  IOS &amp; Android soon. Get ready for it.
                </p>
              </div>
              <div className="text-center">
                <a className="buy-nfts" href="#">
                  <i className="fa-solid fa-star" /> PLAY NOW
                  <i className="fa-solid fa-star" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="artiliin">
              <div className="pics">
                <img src="wp-content/uploads/2022/10/artili_rw4a.png" alt="" />
              </div>
              <div className="artiliin_inn">
                <h4>
                  MERCH <br /> STORE
                </h4>
                <p>
                  T-shirts, Hats, OG Suites and more available to purchase to
                  help spread the awareness of the #1 crypto army. Purchase
                  &amp; recruit.
                </p>
              </div>
              <div className="text-center">
                <a className="buy-nfts" href="#">
                  <i className="fa-solid fa-star" /> VISIT{" "}
                  <i className="fa-solid fa-star" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
