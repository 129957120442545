import React, { useContext } from "react";
import { AppContext } from "../utils";
import { useWeb3Modal } from "@web3modal/wagmi/react";
export default function Header() {
  const { account } = useContext(AppContext);
  const { open } = useWeb3Modal();
  return (
    <header className="header_sec">
      <div className="presalebx">
        <p>
          <i className="fa-sharp fa-solid fa-star" />
          Presale Stage 1<span>live</span>
          <a href="#presBox" className="buy-now" id="buys">
            buy Now
          </a>
          <i className="fa-sharp fa-solid fa-star" />
        </p>
      </div>
      <div className="header_menu">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light nav_top">
            <a className="navbar-brand" href="index.html">
              <img src="wp-content/uploads/2022/10/logo.png" alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav menu_sec mr-auto">
                <li
                  id="menu-item-131"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-131 actv"
                >
                  <a
                    title="Home"
                    href="#banner"
                    data-ps2id-api="true"
                    className=""
                  >
                    Home
                  </a>
                </li>
                <li
                  id="menu-item-25"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-25 actv"
                >
                  <a
                    title="Main Mission"
                    href="#mission"
                    data-ps2id-api="true"
                    className=""
                  >
                    Main Mission
                  </a>
                </li>
                <li
                  id="menu-item-26"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-26 actv"
                >
                  <a
                    title="How To Join"
                    href="#buy"
                    data-ps2id-api="true"
                    className=""
                  >
                    How To Join
                  </a>
                </li>
                <li
                  id="menu-item-27"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-27 actv"
                >
                  <a
                    title="Charities"
                    href="#utility"
                    data-ps2id-api="true"
                    className=""
                  >
                    Charities
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav menu_sec ml-auto">
                <li
                  id="menu-item-28"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-28 actv"
                >
                  <a
                    title="Battle Plan"
                    href="#road"
                    data-ps2id-api="true"
                    className=""
                  >
                    Battle Plan
                  </a>
                </li>
                <li
                  id="menu-item-207"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-207 actv"
                >
                  <a title="FAQ" href="#faq" data-ps2id-api="true" className="">
                    FAQ
                  </a>
                </li>
                <li
                  id="menu-item-29"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-29 actv"
                >
                  <a
                    title="Meet The Army"
                    href="#team"
                    data-ps2id-api="true"
                    className=""
                  >
                    Meet The Army
                  </a>
                </li>
                <li
                  id="menu-item-30"
                  className="buybtn menu-item menu-item-type-custom menu-item-object-custom menu-item-30"
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    title="Buy Now"
                    // href="#presBox"
                    data-ps2id-api="true"
                    className=""
                    style={{ cursor: "pointer", color: "#ffffff" }}
                    onClick={async () => {
                      await open();
                    }}
                  >
                    {account ? account?.slice(0, 7) + "..." : "Connect"}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <span className="buybtn bymob">
            <a href="#presBox">Buy Now</a>
          </span>
        </div>
      </div>
    </header>
  );
}
