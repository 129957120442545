/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Dialog, DialogContent, Stack } from "@mui/material";
import React from "react";
import { Transition } from "./NetworkDialog";
import logoDialouge from "./Images/logoDialouge.png";

function DisclaimerDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="modal__main__container">
        <Dialog
          open={open}
          keepMounted
          TransitionComponent={Transition}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent
            className="dialoge__content__section"
            style={{
              background: "#101507",
            }}
          >
            <Stack alignItems={"center"} py={2}>
              <Box
                component={"img"}
                alt=""
                src={logoDialouge}
                width={"130px"}
              />

              <Box
                mt={2}
                fontWeight={400}
                color="#ffffff"
                fontSize="20px"
                textAlign="center"
                lineHeight="30px"
              >
                Disclaimer for UK residents: OG Army does not meet the FCA and
                UK Government requirements to target UK residents with services.
                If you are a resident of the UK, please do not continue using
                our website
              </Box>
              <Stack mt={3} gap={2}>
                <a
                  onClick={handleClose}
                  style={{ cursor: "pointer", width: "200px" }}
                  className="connect-wall"
                >
                  Continue to site
                </a>
                <a
                  href="https://www.google.com"
                  style={{
                    cursor: "pointer",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  Leave the site
                </a>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default DisclaimerDialog;
