import React from "react";

export default function FAQs() {
  return (
    <section className="faqsec tophead" id="faq">
      <div className="container">
        {/* 		<h2>Frequently Asked Questions</h2>
          
           */}
        <h2>
          Frequently Asked
          <br /> <strong>Questions</strong>
        </h2>
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header" id="heading1">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left "
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse1"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  What is $OGARMY Token?{" "}
                </button>
              </h2>
            </div>
            <div
              id="collapse1"
              className="collapse show"
              aria-labelledby="heading1"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <p>
                  $OGARMY is OG Army's primary token used for trading,
                  purchasing merchandise &amp; acquiring OG NFTs in order to
                  play our P2E game, OG Army: The Beginning of a Rebelion.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="heading2">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse2"
                  aria-expanded="true"
                  aria-controls="collapse2"
                >
                  How Will OG Army Support Charities?{" "}
                </button>
              </h2>
            </div>
            <div
              id="collapse2"
              className="collapse "
              aria-labelledby="heading2"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <p>
                  As explained in our <a href="whitepaper.pdf">whitepaper</a>, a
                  proportion of our token revenue will be reserved for
                  charitable donations which support our main mission.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="heading3">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse3"
                  aria-expanded="true"
                  aria-controls="collapse3"
                >
                  How Can I Buy $OGARMY Tokens?{" "}
                </button>
              </h2>
            </div>
            <div
              id="collapse3"
              className="collapse "
              aria-labelledby="heading3"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <p>
                  $OGARMY tokens can be purchased through our website via our
                  primary payment supplier, Transak. You can use ETH or USDT by
                  connecting your wallet securely.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="heading4">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse4"
                  aria-expanded="true"
                  aria-controls="collapse4"
                >
                  Do You Offer Staking?{" "}
                </button>
              </h2>
            </div>
            <div
              id="collapse4"
              className="collapse "
              aria-labelledby="heading4"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <p>
                  Our state-of-the-art staking platform will be ready in Q3
                  2024, giving you rights to earn up to 45% APY.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="heading5">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse5"
                  aria-expanded="true"
                  aria-controls="collapse5"
                >
                  Where Can I Contact You For Support?{" "}
                </button>
              </h2>
            </div>
            <div
              id="collapse5"
              className="collapse "
              aria-labelledby="heading5"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <p>
                  Please visit our <a href="#">Telegram channel</a> and tag
                  anyone of our admins for help and assistance.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="heading6">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse6"
                  aria-expanded="true"
                  aria-controls="collapse6"
                >
                  What is $JUNGLE Token?{" "}
                </button>
              </h2>
            </div>
            <div
              id="collapse6"
              className="collapse "
              aria-labelledby="heading6"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <p>
                  $JUNGLE tokens are earned in our P2E game and can be swapped
                  into $OGARMY tokens, allowing you to use them in our
                  marketplace for various enhancements to your gameplay, or
                  swapped into other cryptocurrencies such as ETH.
                </p>
              </div>
            </div>
          </div>
          {/* <div class="card">
      <div class="card-header" id="headingTwo">
  <h2 class="mb-0">
    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Collapsible Group Item #2
    </button>
  </h2>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
  <div class="card-body">
    Some placeholder content for the second accordion panel. This panel is hidden by default.
  </div>
      </div>
    </div>
    
    <div class="card">
      <div class="card-header" id="headingThree">
  <h2 class="mb-0">
    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Collapsible Group Item #3
    </button>
  </h2>
      </div>
      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
  <div class="card-body">
    And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
  </div>
      </div>
    </div> */}
        </div>
      </div>
    </section>
  );
}
